import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const SEO = ({ description = '', lang, meta, url = '', title = '', image='' }) => {


    const metaDescription = description

    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={title}
            meta={[
                {
                    name: `og:locale`,
                    content: 'en-us',
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:url`,
                    content: url,
                },

                {
                    name: `image`,
                    content: image,
                },
                {
                    property: "og:image",
                    content: image,
                },
                {
                    property: "og:image:width",
                    content: 1200,
                },
                {
                    property: "og:image:height",
                    content: 600,
                },
                {
                    property: "og:image:alt",
                    content: title,
                },
                {
                    name: `twitter:image:alt`,
                    content: title,
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: `twitter:image`,
                    content: image,
                },

                {
                    name: `twitter:creator`,
                    content: `@invozone`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:site`,
                    content: `@invozone`,
                },

                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:url`,
                    content: url,
                },

                {
                    name: `robots`,
                    content: `index, follow`,
                },
                {
                    name: `robots`,
                    content: `max-snippet:-1`,
                },
                {
                    name: `googlebot`,
                    content: `index, follow`,
                },
                {
                    name: `googlebot-news`,
                    content: `index, follow`,
                },
                {
                    name: `googlebot-news`,
                    content: `max-snippet:-1`,
                },
                {
                    name: `keywords`,
                    content: `News Blogs Latest Hot News`,
                },
            ].concat(
                image
                    ? [
                        {
                            property: "og:image",
                            content: image,
                        },
                        {
                            name: "twitter:card",
                            content: "summary_large_image",
                        },
                    ]
                    : [
                        {
                            name: "twitter:card",
                            content: "summary",
                        },
                    ]
            )
                .concat(meta)}
        >
            <script type="module" src="https://unpkg.com/x-frame-bypass"></script>
            <script src="https://unpkg.com/@ungap/custom-elements-builtin"></script>

        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
