import React from "react"
import * as styles from "./LeftSideSection.module.css";
import {Adsense} from './GoogleAdsense'

const LeftSideSection = (props) => {
  const path = props.location?.pathname || ''

  return (
    <section className={styles.container}>
      <p>ads</p>
         <Adsense path={path}/>

    </section>
  );
};

export default LeftSideSection;