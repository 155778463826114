import React from "react"
import * as styles from "./RightSideSection.module.css";
import RightSideRssFeedList from"./RightSideRssFeedList"
import {Adsense}  from './GoogleAdsense'


const RightSideSection = (props) => {
  const { title, children } = props;
  const path = props.location?.pathname || '';

  return (
    <section className={styles.container}>
    <Adsense path={path}/>

 <RightSideRssFeedList />
    </section>
  );
};

export default RightSideSection;