import React from "react";
import Header from "./Header";
import * as styles from "./Layout.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import herderImage from '../images/header.png'
import {Navbar} from 'react-bootstrap'
import RightSideSection from "./RightSideSection";
import LeftSideSection from "./LeftSideSection";
import { Link } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HeaderRss from "./HeaderRss";

const Layout = ({ children }) => {
  const breakPoints = useBreakpoint();
  return (
    breakPoints.pc ?
    <>
    <Navbar.Brand as={Link} href='/' >
      <img src={herderImage} style={{width:`100%`,height:`200px`,}}/>
      </Navbar.Brand>
      <Header />
  
      <div className={styles.container}>
      <div className={styles.LeftSideSection}>
<LeftSideSection  />
</div>
      <div className={styles.mainPane}>
<div className={styles.headerRssContainer}>
        <HeaderRss/>
        </div>
{children}
</div>
   
<div className={styles.RightSideSection}>
<RightSideSection />
</div>


      
      </div>


      <footer className={styles.footer}>
        © {new Date().getFullYear()}, 
        {`世界の衝撃動画集`}
      </footer>

    </>:


//スマホ
<div className={styles.container}>

    <Navbar.Brand as={Link} href='/' >


      <img src={herderImage} style={{width:`100%`,height:`200px`,}}/>
      </Navbar.Brand>
      <Header />
      <HeaderRss/>

      <main className={styles.mainPane}>{children}</main>

    <footer className={styles.footer}>
        © {new Date().getFullYear()}, 
        {`世界の衝撃動画集`}
      </footer>
      
      </div>

  );
}; 


export default Layout;
