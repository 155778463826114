import React, { useEffect } from 'react'

export const Adsense = ({ path }) => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [path])
  
  return (
    <ins 
      className="adsbygoogle"
      style={{ "display": "block" , textAlign: "center"  }}
      data-ad-client={process.env.GATSBY_GOOGLE_ADSENSE_CLIENT_ID}
      data-ad-slot={process.env.GATSBY_GOOGLE_ADSENSE_SLOT}
      data-ad-format="auto"
      data-full-width-responsive="true"
      data-adtest="on"

    />
  )
}