import { Link ,useStaticQuery,graphql} from "gatsby";
import React from "react";
import * as styles from "./Header.module.css";
import {Nav,Navbar,NavbarBrand,NavItem,Button} from 'react-bootstrap'



const Header = () => {
  const {allStrapiCategory} = useStaticQuery(
    graphql`
    query {
      allStrapiCategory {
        nodes {
          name
          slug
          viewName
        }
      }
    }
    ` )
  return (
    <header className={styles.header}>
     
     


{allStrapiCategory.nodes.map(category=>{
    return (
    <Link to={`/categories/${category.slug}` } className={styles.link}>
    <Button className={styles.categoryButton}variant='warning' style={{padding:`5px`,fontSize:`10px`,margin:`5px`}}>{category.viewName}</Button></Link>)
 })} 


   
    </header>
  );
};

export default Header;
