import React from "react";
import { Link } from "gatsby";
import {Row,Col,Card,Button,container,OverlayTrigger} from 'react-bootstrap'
import * as styles from"./RightSideFeedCell.module.css"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const RightSideFeedCell = (props) => {
  const breakPoints = useBreakpoint();

  const {
    title,
    pubDate,
    link,
    content,
    id,} = props.feed;
  return (
    breakPoints.pc ?


      <Card className={styles.container} >
            <Link to={`${link}` } className={styles.link} target="_blank">

          <Card.Img className="m-0" variant="top" style={{width:`100%`,height:`100%*0.5`}} src={content.match(/https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g)}
 alt={title} ></Card.Img>
          <Card.Body  className={styles.cardContents} >
<Card.Title  variant="light" className={styles.cardTitle}>     {title}
</Card.Title>


        </Card.Body>
        </Link>

      </Card>:

      //スマホ
<Card className={styles.container} >
            <Link to={`${link}` } className={styles.link} target="_blank">

          <Card.Img className="m-0" variant="top" style={{width:`144px`,height:`81px`}} src={content.match(/https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g)}
 alt={title} ></Card.Img>
          <Card.Body  className={styles.cardContents} >
<Card.Title  variant="light" className={styles.cardTitle}>     {title}
</Card.Title>


        </Card.Body>
        </Link>

      </Card>
      

    

    

    
  );
};

export default RightSideFeedCell;