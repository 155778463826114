import React from "react"
import HeaderFeedCell from "./HeaderFeedCell";
import * as styles from"./HeaderRssFeedList.module.css"
import { graphql,useStaticQuery} from "gatsby";

const HeaderRssFeedList = () => {
  
  const {allFeedAlfalfa} = useStaticQuery(
    graphql`
    query {
      allFeedAlfalfa (limit:16) {
        nodes {
            title
            pubDate
            link
            content
            id
        }
        }
    }
    ` )


    if(allFeedAlfalfa !=undefined){
      return (
  
        <div className={styles.container}>
      {allFeedAlfalfa.nodes.map(feed => {
                        return <HeaderFeedCell feed={feed} />
                    })}
        </div>
      )
    }else{
      return(
        <ul>
          <li>
            データが格納されていません。
          </li>
        </ul>
      )
    }



}



export default HeaderRssFeedList;
