import React from "react"
import * as styles from"./RightSideRssFeedList.module.css"
import { graphql,useStaticQuery} from "gatsby";
import RightSideFeedCell from "./RightSideFeedCell";

const RightSideRssFeedList = () => {
  const {allFeedKarapaia} = useStaticQuery(
    graphql`
    query {
        allFeedKarapaia(limit: 5) {
            nodes {
              title
              pubDate
              content
              id
              link
            }
          }
    }
    ` )


    if(allFeedKarapaia !=undefined){
      return (
  
        <div className={styles.container}>
      {allFeedKarapaia.nodes.map(feed => {
                        return <RightSideFeedCell feed={feed} />
                    })}
        </div>
      )
    }else{
      return(
        <ul>
          <li>
            データが格納されていません。
          </li>
        </ul>
      )
    }



}



export default RightSideRssFeedList;
