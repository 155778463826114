import *as styles from './HeaderRss.module.css'
import * as React from "react";
import { graphql } from "gatsby";
import HeaderRssFeedList from './HeaderRssFeedList';
const HeaderRss = () => {
    return (

        <div className={styles.container}>
<HeaderRssFeedList/>
        </div>
    )

}

export default HeaderRss;